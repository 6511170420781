<template>
	<div class="bubble">
		<slot></slot>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.bubble {
	background: #f5f7f9;
	border-radius: 10px;
	padding: 15px;
}
.smallFont {
	font-size: small;
}
</style>
