<template>
	<v-row justify="center">
		<v-dialog v-model="show" persistent max-width="500">
			<v-card>
				<v-card-title class="headline">{{ Header }}</v-card-title>
				<v-card-text><slot /></v-card-text>
				<v-card-actions>
					<v-btn :color="NoColor" depressed dark @click="CloseResult(false)">
						{{ NoBtn }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn :color="YesColor" depressed dark @click="CloseResult(true)">
						{{ YesBtn }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
/**
 * This is a yes no dialog component that allows you to ask the user a yes no question
 */
export default {
	name: "YesNoModal",
	props: {
		/**
		 * This controls if the modal is visible or not
		 * @values true, false
		 */
		Display: {
			default: false,
		},

		/**
		 * Popup header giving a breif description
		 * @values any string
		 */
		Header: { default: "You need to set the `Header` prop" },

		/**
		 * Message content, typically a paragraph
		 * @values any string
		 */
		Message: { default: "You need to set the `Message` prop" },

		/**
		 * This is the No button text
		 * @values any string
		 */
		NoBtn: { default: "No" },

		/**
		 * This is the Yes button text
		 * @values any string
		 */
		YesBtn: { default: "Yes" },

		/**
		 * This is the color of the No button
		 * @values primary, secondary, accent, error, info, success, warning
		 */
		NoColor: { default: "red darken-1" },

		/**
		 * this is the color of the yes button
		 * @values primary, secondary, accent, error, info, success, warning
		 */
		YesColor: { default: "green darken-1" },

		value: { default: false },
	},
	data() {
		return {
			show: false,
		};
	},
	mounted() {
		this.setShow();
	},
	watch: {
		value() {
			this.$nextTick(() => {
				this.setShow();
			});
		},
		Display() {
			this.$nextTick(() => {
				this.setShow();
			});
		},
	},
	methods: {
		setShow() {
			this.show = this.Display || this.value;
		},
		CloseResult(res) {
			/**
			 * The result of the dialog, `false` for no, and `true` for yes
			 *
			 * @event Result
			 * @property {boolean} res The result of the dialog, `false` for no, and `true` for yes
			 */
			this.$emit("Result", res);
			this.$emit("input", !this.show);
		},
	},
};
</script>

<style></style>

<docs>
```vue
let show = false

function HandleResult(result){
  console.log(result);
  if(result){
    outMessage = "You should pet my cats sometime";
  }else{
    outMessage = "I don't think we can be friends";
  }
  alert(outMessage);
  show = false;
}

<v-btn @click="show=!show" color="primary">Ask</v-btn>
<br>
<b>{{outMessage}}</b>

<YesNoModal
  :Display="show"
  @Result="HandleResult"

  Message="I gotta know, do you like cats?"
  Header="Do you like cats?"

  NoBtn="Nope, I like dogs"
  NoColor="secondary"

  YesBtn="Yes, cats are dope"
  YesColor="primary"
/>
```
</docs>
