<template>
	<blurb>
		<v-row>
			<v-col cols="1">
				<v-icon v-if="icon">{{ icon }}</v-icon>
				<v-tooltip bottom v-else>
					<template v-slot:activator="{ on, attrs }">
						<v-avatar
							color="info"
							size="32"
							style="color:white"
							v-bind="attrs"
							v-on="on"
						>
							{{ avatar }}
						</v-avatar>
					</template>
					<span>{{ name }}</span>
				</v-tooltip>
			</v-col>
			<v-col>
				<v-row dense class="smallFont" v-if="headerSet">
					<v-col>
						<slot name="header" class="smallFont" />
						<v-divider />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<slot />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</blurb>
</template>

<script>
import Blurb from "@/components/Blurb.vue";
export default {
	props: {
		icon: {
			default: null,
		},
		avatar: {
			default: null,
		},
		name: {
			default: null,
		},
	},
	components: { Blurb },
	mounted() {
		if (this.icon && this.avatar) {
			console.error("Cannot use both icon and avatar props at the same time");
		} else if (!this.icon && !this.avatar) {
			console.error("Icon or avatar prop must be specified");
		}

		if (this.avatar && !this.name) {
			console.error("name prop must be specified when the avatar is specified");
		}
	},
	computed: {
		headerSet() {
			// eslint-disable-next-line
			return !!this.$slots.header;
		},
	},
};
</script>

<style></style>
