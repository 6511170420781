<template>
	<div>
		<details-blurb icon="mdi-phone">
			<template #header>
				Voicemail From:
				{{ value.callername }} - {{ value.phone }}
				<br />
				{{ value.origdate | diffForHumans }}
				<br />
				Mailbox: {{ value.MailboxTitle }} ({{ value.MailboxExtension }})
				<v-divider />
			</template>

			<v-row class="mt-2">
				<v-col>
					{{ value.TRANSCRIPTION }}
				</v-col>
			</v-row>
			<v-row align="center" class="mt-3">
				<v-col cols="1">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								fab
								color="primary"
								small
								v-bind="attrs"
								v-on="on"
								@click="getAndPlayAudio()"
							>
								<v-icon>
									mdi-play
								</v-icon>
							</v-btn>
						</template>
						<span>Play Message</span>
					</v-tooltip>
				</v-col>
				<v-col></v-col>
				<v-col cols="1" align="right">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								fab
								text
								color="info"
								x-small
								v-bind="attrs"
								v-on="on"
								@click="showMessageLog = true"
							>
								<v-icon>
									mdi-information-variant
								</v-icon>
							</v-btn>
						</template>
						<span>Full Message Log</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</details-blurb>
		<alert v-model="showAudio" Header="Play Message">
			<div v-if="showAudio">
				<center v-if="audioBlob">
					<audio controls autoplay class="mt-5">
						<source :src="audioBlob" type="audio/wav" />
					</audio>
				</center>
				<center v-else>
					<v-progress-circular color="primary" size="48" indeterminate />
				</center>
			</div>
		</alert>
		<alert v-model="showMessageLog" Header="Message Details from Asterisk">
			<div v-html="fullLog" class="background-for-log consolas"></div>
		</alert>
	</div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import { mapState } from "vuex";
import Alert from "@/components/ModalMessages/Alert.vue";
import DetailsBlurb from "./DetailsBlurb.vue";
export default {
	components: { Alert, DetailsBlurb },
	props: {
		value: {
			default: null,
		},
	},
	data() {
		return {
			showAudio: false,
			showMessageLog: false,
			audioBlob: null,
		};
	},
	computed: {
		...mapState(["Organization"]),
		fullLog() {
			return this.value.fulllog.replaceAll("\n", "<br>");
		},
		ticketID() {
			return this.$route.params.ticketid;
		},
		MailboxID() {
			return this.$route.params.mbid;
		},
	},
	methods: {
		async getAndPlayAudio() {
			this.showAudio = true;
			try {
				let res = await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.MailboxID}/stream/${this.ticketID}`,
					{ responseType: "blob" }
				);

				this.audioBlob = URL.createObjectURL(res.data);
			} catch (error) {
				console.log(error);
				if (error.response) console.log(error.response);
			}
		},
	},
	filters: {
		diffForHumans: (date) => {
			if (!date) {
				return null;
			}

			const d = dayjs(date);
			return d.format("dddd, MMMM D") + " at " + d.format("hh:mm A");
		},
	},
};
</script>

<style scoped>
.consolas {
	font-family: Consolas, monaco, monospace;
	font-size: 13px;
	font-style: normal;
	font-variant: normal;
	font-weight: 700;
}
.background-for-log {
	background: #f5f5f5;
	border-radius: 10px;
	padding: 5px;
}
</style>
