<template>
	<v-skeleton-loader :loading="!loaded" type="card">
		<div class="pb-10">
			<v-btn class="mb-2" to="/tickets" dark small>
				<v-icon>
					mdi-chevron-left
				</v-icon>
				Back
			</v-btn>
			<div v-if="loaded">
				<v-row align="center" dense>
					<v-col>
						<h1>Ticket #{{ $route.params.ticketid }}</h1>
					</v-col>
					<v-col cols="4" align="end">
						<v-btn
							@click="ShowDetailsPane = !ShowDetailsPane"
							color="info"
							small
						>
							{{ ShowDetailsPane ? "Hide Details" : "Show Details" }}
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<header-blurb v-model="ticketData" />
						<div class="mt-11"></div>
						<DetailsBlurb
							:class="
								`mt-5 ${
									deleteItem
										? deleteItem.id == item.id
											? 'alertBackground'
											: ''
										: ''
								}`
							"
							v-for="item in messages"
							:key="item.id"
							:avatar="item.initals"
							:name="item.CreatedBy"
							:id="`msg-${item.id}`"
						>
							<template #header>
								<v-row>
									<v-col
										>Note Created By: {{ item.CreatedBy }}
										<br />
										Created: {{ item.timestamp | diffForHumans }}
									</v-col>
									<v-col cols="1" align="end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn v-bind="attrs" v-on="on" text fab small>
													<v-icon>
														mdi-dots-vertical
													</v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item-group>
													<v-list-item @click="deleteMessagePrompt(item)">
														<v-list-item-icon>
															<v-icon>
																mdi-delete
															</v-icon>
														</v-list-item-icon>
														<v-list-item-title>
															Delete
														</v-list-item-title>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</v-menu>
									</v-col>
								</v-row>
								<v-divider />
							</template>

							<span v-html="item.Message"></span>
						</DetailsBlurb>
						<div class="mt-5" v-show="displayAddMessage">
							<v-textarea
								v-model="message"
								no-resize
								outlined
								label="Add Note"
								auto-grow
							>
							</v-textarea>
						</div>
						<blurb :class="displayAddMessage ? '' : 'mt-5'">
							<v-row>
								<v-col>
									<v-btn
										:color="displayAddMessage ? 'error' : 'primary'"
										:disabled="saving"
										@click="toggleDisplayMessage()"
									>
										<v-icon class="mr-3">
											{{ displayAddMessage ? "mdi-close" : "mdi-plus-circle" }}
										</v-icon>
										{{ displayAddMessage ? "Cancel" : "Add Note" }}
									</v-btn>
								</v-col>
								<v-col align="end">
									<v-btn
										v-show="displayAddMessage"
										color="primary"
										:loading="saving"
										@click="submitNewMessage"
									>
										<v-icon class="mr-3">
											mdi-check
										</v-icon>
										Add Message
									</v-btn>
								</v-col>
							</v-row>
						</blurb>
					</v-col>
					<v-col v-if="ShowDetailsPane" cols="4">
						<blurb>
							<h3>Ticket Details</h3>
							<v-divider class="my-2"></v-divider>
							<v-row>
								<v-col>
									<v-select
										v-model="ticketData.status"
										:loading="updating"
										label="Status"
										:items="statuses"
										@change="updateStatus"
									/>
									<v-autocomplete
										v-model="ticketData.AsigneeID"
										:loading="updating"
										label="Assignee"
										:items="usersInOrg"
										item-text="Name"
										item-value="id"
										@change="updateAsignee"
									/>
									<v-select
										v-model="ticketData.TypeID"
										:loading="updating"
										label="Type"
										:items="types"
										item-text="title"
										item-value="id"
										@change="updateType"
									/>
								</v-col>
							</v-row>
						</blurb>
						<blurb class="mt-3">
							<h3>Message Details</h3>
							<v-divider class="my-2"></v-divider>
							<v-row
								v-for="item in MessageDetails"
								:key="item.Title"
								align="center"
								dense
							>
								<v-col>
									<b>{{ item.Title }}</b>
								</v-col>
								<v-col>{{ item.Value }}</v-col>
							</v-row>
						</blurb>
					</v-col>
				</v-row>
			</div>
		</div>

		<YesNo
			v-model="showCloseAlert"
			Header="Lose your changes?"
			@Result="toggleDisplayMessage"
		>
			<div v-if="showCloseAlert">
				Are you sure you wish to abandon your notes? All changes will be lost.
			</div>
		</YesNo>

		<YesNo
			v-model="deletePrompt"
			Header="Are you sure?"
			@Result="deleteMessage"
		>
			<div v-if="deletePrompt">
				Are you sure that you want to delete the following note?<br />
				<v-divider class="my-3" />
				{{ deleteItem.Message }}
			</div>
		</YesNo>
		<alert v-model="showErrorMessage" Header="Oh no!">
			An error has occured
		</alert>
	</v-skeleton-loader>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import { mapState } from "vuex";
import HeaderBlurb from "@/components/HeaderBlurb.vue";
import DetailsBlurb from "@/components/DetailsBlurb.vue";
import Alert from "@/components/ModalMessages/Alert.vue";
import YesNo from "@/components/ModalMessages/YesNo.vue";
import Blurb from "@/components/Blurb.vue";
export default {
	components: { DetailsBlurb, Alert, HeaderBlurb, YesNo, Blurb },
	data() {
		return {
			ticketData: null,
			messages: null,
			deletePrompt: false,
			deleteItem: null,
			showErrorMessage: false,
			showCloseAlert: false,
			displayAddMessage: false,
			saving: false,
			updating: false,
			message: "",
			ShowDetailsPane: true,
			statuses: ["Created", "Open", "Closed"],
		};
	},
	computed: {
		...mapState(["Organization", "usersInOrg", "types"]),

		loaded() {
			// eslint-disable-next-line
			return !!this.ticketData && !!this.messages;
		},
		fullLog() {
			return this.value.fulllog.replaceAll("\n", "<br>");
		},
		ticketID() {
			return this.$route.params.ticketid;
		},
		MailboxID() {
			return this.$route.params.mbid;
		},
		MessageDetails() {
			const t = this.ticketData;
			const d = dayjs(t.origdate);
			let date = d.format("M/D/YYYY") + " at " + d.format("hh:mm A");
			return [
				{ Title: "Timestamp", Value: date },
				{ Title: "Duration", Value: `${t.duration} seconds` },
				{ Title: "Transcription Status", Value: t.TranscriptionStatus },
				{ Title: "Caller Name", Value: t.callername },
				{ Title: "Caller Phone", Value: t.phone },
				{ Title: "Mailbox Extension", Value: t.MailboxExtension },
				{ Title: "Mailbox Title", Value: t.MailboxTitle },
				{ Title: "Caller phone", Value: t.phone },
				{
					Title: "Voicemail Size",
					Value: `${(t.fileSize / (1024 * 1024)).toFixed(2)} MB`,
				},
			];
		},
	},

	async mounted() {
		// setTimeout(async () => {
		this.ticketData = (
			await axios.get(
				`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.MailboxID}/ticket/${this.ticketID}`
			)
		).data;

		this.loadMessages(true);
	},
	watch: {
		displayAddMessage(v) {
			if (!v) {
				this.message = "";
			}
		},
	},
	filters: {
		diffForHumans: (date) => {
			if (!date) {
				return null;
			}

			const d = dayjs(date);
			return d.format("dddd, MMMM D") + " at " + d.format("hh:mm A");
		},
	},
	methods: {
		updateType() {
			this.updateTicket({ Type: this.ticketData.TypeID });
		},
		updateStatus() {
			this.updateTicket({ status: this.ticketData.status });
		},
		updateAsignee() {
			this.updateTicket({ Asignee: this.ticketData.AsigneeID });
		},
		updateTicket(data) {
			this.updating = true;
			axios
				.patch(
					`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.MailboxID}/ticket/${this.ticketID}`,
					data
				)
				.then(() => {
					this.updating = false;
				})
				.catch((err) => {
					console.log("Error saving: ", err);
					if (err.response) console.log(err.response, err.response.body);
					this.showErrorMessage = true;
				});
		},
		toggleDisplayMessage(close = null) {
			if (this.displayAddMessage && close == null) {
				if (this.message.length > 1) {
					this.showCloseAlert = true;
					return;
				}
			}

			if (close == null || close)
				this.displayAddMessage = !this.displayAddMessage;
		},
		async loadMessages(jumpToBottom = false) {
			this.messages = (
				await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.MailboxID}/ticket/${this.ticketID}/messages`
				)
			).data;

			for (let i = 0; i < this.messages.length; i++) {
				const element = this.messages[i];
				element.initals = element.CreatedBy.split(" ")
					.map((x) => x.substr(0, 1))
					.join("");
				element.Message = element.Message.replaceAll("\n", "<br />");
			}
			if (jumpToBottom)
				this.$nextTick(function() {
					if (this.messages.length >= 1)
						this.$vuetify.goTo(
							`#msg-${this.messages[this.messages.length - 1].id}`
						);
				});
		},
		deleteMessagePrompt(item) {
			this.deleteItem = item;
			this.deletePrompt = true;
		},
		deleteMessage(yn) {
			console.log(yn);
			if (yn) {
				axios
					.delete(
						`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.MailboxID}/ticket/${this.ticketID}/message/${this.deleteItem.id}`
					)
					.then(() => {
						let idx = this.messages.findIndex(
							(x) => x.id == this.deleteItem.id
						);

						this.messages.splice(idx, 1);
						this.clearDeletePrompts();
					})
					.catch((err) => {
						console.log(err);
						if (err.response) console.log(err.response);
						this.clearDeletePrompts();
						this.showErrorMessage = true;
					});
			} else {
				this.clearDeletePrompts();
			}
		},
		clearDeletePrompts() {
			this.deleteItem = null;
			this.deletePrompt = false;
		},
		async submitNewMessage() {
			this.saving = true;
			let body = {
				message: this.message,
			};
			try {
				await axios.post(
					`https://api.ticketvm.com/organization/${this.Organization.id}/mailbox/${this.MailboxID}/ticket/${this.ticketID}/messages`,
					body
				);

				//If message is added and status is created then set status to open
				if (this.messages.length < 1 && this.ticketData.status == "Created") {
					this.ticketData.status = "Open";
					this.updateStatus();
				}
			} catch (err) {
				console.log(err);
				if (err.response) console.log(err.response);
				this.showErrorMessage = true;
			}
			await this.loadMessages();
			this.displayAddMessage = false;
			this.saving = false;
		},
	},
};
</script>

<style scoped>
.alertBackground {
	background: tomato;
}
</style>
